import { FC } from "react";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { Alert, Box, ButtonPropsColorOverrides, Stack } from "@mui/material";
import { OverridableStringUnion } from "@mui/types";
import {
  DialogCustomButton,
  DialogCustomContent,
  DialogCustomTitle,
} from "./styles";

export type IConfirmationDialog = {
  onClose: (props?: any) => void;
  isOpen: boolean;
  title?: string;
  description?: any;
  confirmAction: (args?: any) => void;
  confirmText?: string;
  declineText?: string;
  titleColor?: string;
  descriptionColor?: string;
  confirmButtonColor?: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >;
  error?: any;
  disabled?: boolean;
  withoutDecline?: boolean;
};

export const ConfirmationDialog: FC<IConfirmationDialog> = ({
  isOpen,
  onClose,
  title,
  description,
  confirmAction,
  confirmText = "Ok",
  declineText = "Cancel",
  titleColor,
  descriptionColor,
  confirmButtonColor,
  error,
  disabled,
  withoutDecline = false,
  children,
}) => {
  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          width: "409px",
          borderRadius: "16px",
        },
      }}
      maxWidth='xs'
      open={isOpen}
      id='document-review-dialog'
      keepMounted
    >
      <DialogCustomTitle sx={{ color: titleColor }}>{title}</DialogCustomTitle>
      <DialogCustomContent sx={{ color: descriptionColor }}>
        {description}
        {error && <Alert severity='error'>{error.toString()}</Alert>}
      </DialogCustomContent>
      {children ? (
        <Stack sx={{ padding: "0 32px 32px" }}>{children}</Stack>
      ) : null}
      <DialogActions sx={{ padding: "0 32px 32px" }}>
        <Stack
          flexDirection='row'
          alignItems='center'
          justifyContent={!withoutDecline ? "space-between" : "center"}
          width='100%'
        >
          {!withoutDecline ? (
            <Box>
              <DialogCustomButton
                autoFocus
                onClick={onClose}
                disabled={disabled}
                variant='outlined'
              >
                {declineText}
              </DialogCustomButton>
            </Box>
          ) : null}

          <Box>
            <DialogCustomButton
              onClick={confirmAction}
              disabled={disabled}
              variant='contained'
              color={confirmButtonColor ?? "inherit"}
            >
              {confirmText}
            </DialogCustomButton>
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
