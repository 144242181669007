import { Box, Typography } from "@mui/material";
import { PanelLayout } from "../PanelLayout";
import { mainTheme } from "../../../../theme/mainTheme";

export const DuplicatesPanel = () => (
  <PanelLayout title='Duplicates' after='(0)'>
    <Box marginTop='16px'>
      <Typography
        sx={{
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          color: mainTheme.palette.info.main,
        }}
      >
        No duplicates were found
      </Typography>
    </Box>{" "}
  </PanelLayout>
);
