export const trimLongString = (
  string: string | undefined,
  maxLength: number = 24,
  sliceLength: number = 9
) => {
  if (string === undefined) {
    return "";
  }
  if (string.length > maxLength) {
    return (
      string.slice(0, sliceLength) +
      "..." +
      string.slice(string.length - sliceLength, string.length)
    );
  }
  if (string.length <= maxLength) {
    return string;
  }
  return "";
};
