import { Component, createContext } from "react";

export type VerificationState = {
  inputValue: string;
  countriesRegex: any;
};

export type VerificationContextValue = VerificationState & {
  setInputValue: (value: string) => void;
  setCountriesRegex: (value: string) => void;
};

export const VerificationContext = createContext<VerificationContextValue>(
  {} as VerificationContextValue
);

export class VerificationProvider extends Component<{}, VerificationState> {
  state = {
    inputValue: "",
    countriesRegex: "",
  };

  setInputValue = (value: string) => {
    this.setState({ inputValue: value });
  };

  setCountriesRegex = (value: string) => {
    this.setState({ countriesRegex: value });
  };

  render() {
    const { children } = this.props;

    const contextValue: VerificationContextValue = {
      ...this.state,
      setInputValue: this.setInputValue,
      setCountriesRegex: this.setCountriesRegex,
    };

    return (
      <VerificationContext.Provider value={contextValue}>
        {children}
      </VerificationContext.Provider>
    );
  }
}
