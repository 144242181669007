import { FC, useEffect } from "react";
import { SearchIcon } from "../MuiIcons";
import ClearIcon from "@mui/icons-material/Clear";
import InputAdornment from "@mui/material/InputAdornment";
import { InputStyled } from "./styles";
import useDebounce from "../../modules/ui/hooks/useDebounce";
import { useVerificationContext } from "../../modules/verificationRequest/context/useVerification";

export const SearchInput: FC<{ onChangeValue: (value: string) => void }> = ({
  onChangeValue,
}) => {
  const { inputValue, setInputValue } = useVerificationContext();
  const debouncedSearchValue = useDebounce(inputValue, 500);

  useEffect(() => {
    onChangeValue(debouncedSearchValue);
  }, [debouncedSearchValue, onChangeValue]);

  return (
    <InputStyled
      placeholder='Search email or phone'
      value={inputValue}
      onChange={(event: any) => setInputValue(event?.target?.value)}
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position='start'>
          <span
            onClick={() => setInputValue("")}
            style={{ cursor: "pointer", height: 16, width: 16 }}
          >
            <ClearIcon sx={{ width: 16, height: 16 }} />
          </span>
        </InputAdornment>
      }
    />
  );
};
