import { styled, Tab } from "@mui/material";
import { mainTheme } from "../../theme/mainTheme";

export const TabList = styled(Tab, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active?: boolean }>(({ active }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  padding: "8px 12px",
  background: "none",
  borderRadius: "none",
  border: "none",
  textTransform: "capitalize",
  fontFamily: "Red Hat Display",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  color: mainTheme.palette.info.main,
  ...(active && {
    background: "#ffffff",
    borderRadius: "10px",
  }),
  "&:hover": {
    color: "#40a9ff", //CHECK THIS COLOR
  },
  "&.Mui-selected": {
    color: "black",
  },
}));
