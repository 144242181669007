import { memo } from "react";
import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@mui/material";
import { TVerificationRequestStatistic } from "../../models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  DateGrid,
  DocumentAccordeon,
  StatisticGrid,
  StatiticStatusIcon,
} from "./styles";
import { titleCase } from "../../../../utils";
import { getLocaleStringdDate } from "../../../../utils/dateFormatt";
import { statusesModel, statusIcons } from "../../utils/getStatus";
import { mainTheme } from "../../../../theme/mainTheme";

export type Props = {
  statistic: TVerificationRequestStatistic[] | null;
  data: any;
};

export const StatisticPanel = memo((props: Props) => {
  const { statistic, data } = props;
  const NewIcon = statusIcons["NEW"];
  return (
    <DocumentAccordeon disableGutters>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
      >
        <Typography
          variant='h6'
          sx={{
            fontWeight: "800",
            fontSize: "20px",
            lineHeight: "28px",
            letterSpacing: "0.5px",
            color: "#1C1F21",
            textTransform: "capitalize",
          }}
        >
          Events
        </Typography>
      </AccordionSummary>

      <AccordionDetails sx={{ marginTop: "8px" }}>
        {statistic?.length ? (
          statistic
            ?.filter(
              (elem) =>
                ![
                  "SENT_BY_USER_TO_FINGER_PRINTING",
                  "ALLOW_SEND_TO_VERIFICATION",
                  "NEW",
                ].includes(elem.status)
            )
            ?.reverse()
            .map((elem) => {
              const Icon = statusIcons[elem.status];
              return (
                <StatisticGrid container wrap='nowrap'>
                  <Grid container item>
                    <StatiticStatusIcon>
                      <Icon />
                    </StatiticStatusIcon>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color:
                          elem.status === "processing"
                            ? mainTheme.palette.primary.main
                            : "",
                      }}
                    >
                      {statusesModel[elem.status].name}
                    </Typography>
                  </Grid>
                  <DateGrid container item>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "700",
                        color:
                          elem.status === "processing"
                            ? mainTheme.palette.warning.main
                            : "",
                      }}
                    >
                      {getLocaleStringdDate(elem.createdAt)}
                    </Typography>
                  </DateGrid>
                </StatisticGrid>
              );
            })
        ) : (
          <StatisticGrid container wrap='nowrap'>
            <Grid container item>
              <StatiticStatusIcon>
                <NewIcon />
              </StatiticStatusIcon>

              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  // color: "#2968EF",
                }}
              >
                {titleCase("NEW")}
              </Typography>
            </Grid>
            <DateGrid container item>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "700",
                  // color: "#2968EF",
                }}
              >
                {getLocaleStringdDate(data?.createdAt ?? "")}
              </Typography>
            </DateGrid>
          </StatisticGrid>
        )}
      </AccordionDetails>
    </DocumentAccordeon>
  );
});
