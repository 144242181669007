import { List, Stack, Tooltip } from "@mui/material";
import {
  ApprovedIcon,
  CancelIcon,
  FlameIcon,
  PendingIcon,
} from "../../../../components/MuiIcons";
import { PanelLayout } from "../PanelLayout";
import { FC, useState } from "react";
import { LabelText, ValueText } from "./styles";
import { mainTheme } from "../../../../theme/mainTheme";
import { trimLongString } from "../../../../utils/trimLongString";
import { CustomCopyClipboard } from "../../../ui/components/CustomCopyToClipboard";

export const externalStatusModel = {
  valid: {
    color: mainTheme.palette.success.main,
    icon: <ApprovedIcon viewBox='0 0 20 20' />,
  },
  invalid: {
    color: mainTheme.palette.error.main,
    icon: <CancelIcon viewBox='0 0 20 20' />,
  },
  processing: {
    color: mainTheme.palette.warning.main,
    icon: <PendingIcon />,
  },
  pending: {
    color: mainTheme.palette.warning.main,
    icon: <PendingIcon />,
  },
  new: {
    color: mainTheme.palette.primary.main,
    icon: <FlameIcon viewBox='0 0 20 20' />,
  },
};

export const DatabaseScreeningPanel: FC<{
  data: any;
  isMobile?: boolean;
}> = ({ data, isMobile = false }) => {
  const [openTooltip, setOpenTooltip] = useState(false);

  const tooltipOptions = isMobile
    ? {
        onClick: () => setOpenTooltip((prev) => !prev),
      }
    : {
        onMouseOver: () => setOpenTooltip(true),
        onMouseLeave: () => setOpenTooltip(false),
      };

  if (!data) return null;

  return (
    <PanelLayout title='Database screening'>
      <List>
        <Stack direction='row' spacing={2}>
          <LabelText>Current status</LabelText>
          {data.externalStatus && (
            <ValueText
              sx={{
                color:
                  externalStatusModel[
                    data.externalStatus as keyof typeof externalStatusModel
                  ]?.color,
              }}
            >
              {
                externalStatusModel[
                  data.externalStatus as keyof typeof externalStatusModel
                ]?.icon
              }
              {data.externalStatus}
            </ValueText>
          )}
        </Stack>
        <Stack alignItems='flex-start' direction='row' spacing={2} mt={2}>
          <LabelText>Ongoing monitoring (daily screening):</LabelText>
          <ValueText>{data.externalStatus === "valid" ? "ON" : ""}</ValueText>
        </Stack>
        <Stack direction='row' alignItems='flex-start' spacing={2} mt={2}>
          <LabelText>External applicant ID:</LabelText>
          <Tooltip
            open={openTooltip}
            {...tooltipOptions}
            arrow
            classes={{ arrow: "arrow", tooltip: "tooltip" }}
            placement={isMobile ? "top-start" : "top"}
            title={data.refApplicantId}
          >
            <ValueText>
              {trimLongString(data.refApplicantId, 24, isMobile ? 4 : 6)}
              {data.refApplicantId ? (
                <CustomCopyClipboard text={data.refApplicantId} />
              ) : null}
            </ValueText>
          </Tooltip>
        </Stack>
        {data.declineReason?.length > 0 && (
          <Stack direction='row' spacing={2} mt={2}>
            <LabelText>Decline reason</LabelText>
            <ValueText>
              {data.declineReason
                .map((reason: string) => reason.replace(/_/g, " "))
                .join(", ")}
            </ValueText>
          </Stack>
        )}
      </List>
    </PanelLayout>
  );
};
