import { createTheme } from "@mui/material";

const palette = {
  background: {
    default: "#e5e5e5",
  },
  success: {
    // main: "#2e7d32",
    main: "#2CC369",
    contrastText: "#fff",
  },
  warning: {
    main: "#D2B102",
  },
  info: {
    main: "#8D959F",
  },
};

export const mainTheme = createTheme({
  palette,
  typography: {
    fontFamily: ['"Red Hat Display"', "sans-serif"].join(","),
    h1: {
      fontWeight: "800",
      fontSize: "32px",
      lineHeight: "40px",
      "@media screen and (max-width: 850px)": {
        fontSize: "24px",
        lineHeight: "20px",
      },
    },
    h5: {
      fontSize: "24px",
      lineHeight: "24px",
      "@media screen and (max-width: 850px)": {
        fontSize: "18px",
        lineHeight: "14px",
      },
    },
    h6: {
      fontSize: "20px",
      lineHeight: "20px",
      "@media screen and (max-width: 850px)": {
        fontSize: "16px",
        lineHeight: "12px",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 850,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },
    MuiFilledInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          overflow: "hidden",
          background: "#F4F4F4",
          borderRadius: "12px",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        variant: "filled",
      },
      styleOverrides: {
        root: {
          fontWeight: "700",
          fontSize: "12px",
          lineHeight: "16px",
          letterSpacing: "0.5px",
          color: palette.info.main,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: "capitalize",
          borderRadius: "12px",
        },
      },
    },
  },
});
