import { Button, DialogContent, DialogTitle, styled } from "@mui/material";

export const DialogCustomTitle = styled(DialogTitle)`
  padding: 32px 32px 0;
  text-align: center;
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.5px;
  color: #1c1f21;
`;

export const DialogCustomContent = styled(DialogContent)`
  padding: 0 32px 32px;
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #1c1f21;
`;

export const DialogCustomButton = styled(Button)`
  padding: 16px 24px;
  width: 164.5px;
  height: 54px;
  border-radius: 12px;
  text-transform: capitalize;
  @media screen and (max-width: 850px) {
    width: 124px;
  }
`;
