import { Box, Typography } from "@mui/material";
import { FilledTextArea } from "../../../../components/FilledTextArea";
import { useVerificationContext } from "../../context/useVerification";
import { getParsedTaxId } from "../../utils/getParsedTaxId";

export const FinancialInformation = (props: {
  onChange: any;
  name: string;
  fields: any;
  label: string;
  disabled?: boolean;
}) => {
  const { onChange, fields, name, label, disabled } = props;
  const { countriesRegex } = useVerificationContext();
  if (name === "tax_id" && fields[name]) {
    try {
      const taxIds = JSON.parse(fields[name]);

      return (
        <>
          {taxIds.map((taxId: any) => {
            const { viewCountry, check, value, example } = getParsedTaxId(
              taxId,
              countriesRegex
            );
            return (
              <Box>
                <FilledTextArea
                  sx={{
                    label: {
                      color:
                        check === "none"
                          ? null
                          : check
                          ? "green !important"
                          : "orange !important",
                    },
                  }}
                  multiline={true}
                  name={name}
                  label={
                    `${viewCountry} ${label}` +
                    (check ? "" : " (check the format)")
                  }
                  inputProps={{ onChange, disabled }}
                  value={value ?? ""}
                />
                <Typography variant='caption' paddingLeft='12px'>
                  {example ?? ""}
                </Typography>
              </Box>
            );
          })}
        </>
      );
    } catch (e) {
      console.warn("Old TIN format");
    }
  }
  return (
    <FilledTextArea
      multiline={true}
      name={name}
      label={label}
      inputProps={{ onChange, disabled }}
      value={fields[name] ?? ""}
    />
  );
};
