import { SvgIconProps } from "@mui/material";
import {
  ApprovedIcon,
  FolderIcon,
  CancelIcon,
  PendingIcon,
} from "../../../components/MuiIcons";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { mainTheme } from "../../../theme/mainTheme";

export const statusIcons: {
  [key: string]: any;
} = {
  NEW: (props: SvgIconProps) => (
    <PendingIcon {...props} fill={mainTheme.palette.primary.main} />
  ),
  SENT_TO_VERIFICATION: PendingIcon,
  ACCEPTED: ApprovedIcon,
  CANCELED: CancelIcon,
  DELETED: (props: SvgIconProps) => (
    <DeleteIcon
      sx={{ height: 20, color: mainTheme.palette.error.main }}
      {...props}
    />
  ),
  INFORMATION_REQUESTED: FolderIcon,
};

export const statusesModel: {
  [key: string]: { bgColor: string; name: string; textColor: string };
} = {
  NEW: {
    bgColor: `${mainTheme.palette.primary.main}1A`,
    name: "New",
    textColor: mainTheme.palette.primary.main,
  },
  SENT_TO_VERIFICATION: {
    bgColor: `${mainTheme.palette.warning.main}1A`,
    name: "Sent to verification",
    textColor: mainTheme.palette.warning.main,
  },
  ACCEPTED: {
    bgColor: `${mainTheme.palette.success.main}1A`,
    name: "Accepted",
    textColor: mainTheme.palette.success.main,
  },
  CANCELED: {
    bgColor: `${mainTheme.palette.error.main}1A`,
    name: "Rejected",
    textColor: mainTheme.palette.error.main,
  },
  DELETED: {
    bgColor: `${mainTheme.palette.error.main}1A`,
    name: "Deleted",
    textColor: mainTheme.palette.error.main,
  },
  INFORMATION_REQUESTED: {
    bgColor: `${mainTheme.palette.info.main}1A`,
    name: "Information requested",
    textColor: mainTheme.palette.info.main,
  },
};

export const getStatusIcon = (
  documentStasus: string,
  props: any = {}
): JSX.Element => {
  if (documentStasus in statusIcons) {
    const Icon = statusIcons[documentStasus];
    return <Icon {...props} />;
  }

  return <></>;
};
