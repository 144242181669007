import { styled, IconButton } from "@mui/material";
import { mainTheme } from "../../theme/mainTheme";

export const CustomIconButton = styled(IconButton)`
  width: 64px;
  height: 64px;
  &:hover {
    background: #eef3fe;
    border-radius: 14px;
  }
  &.active {
    background: ${mainTheme.palette.primary.main};
    box-shadow: 0px 6px 13px rgba(6, 66, 195, 0.28),
      0px 1.80882px 2.52044px rgba(6, 66, 195, 0.0903767),
      0px 0.751293px 1.10391px rgba(6, 66, 195, 0.0892929),
      0px 0.271728px 0.468719px rgba(6, 66, 195, 0.094491);
    border-radius: 14px;
    color: #ffffff;
  }
  @media screen and (max-width: 850px) {
    width: 40px;
    height: 40px;
  }
`;
