import { styled, Box, Typography } from "@mui/material";
import { mainTheme } from "../../../../theme/mainTheme";

export const PageWrapper = styled(Box)`
  width: 100%;
  min-height: 116px;
  padding: 20px 32px;
  background: #ffffff;
  box-shadow: 0px 9px 54px rgba(0, 0, 0, 0.07),
    0px 2.01px 12.0616px rgba(0, 0, 0, 0.0234994),
    0px 0.6px 3.59106px rgba(0, 0, 0, 0.017426);
  border-radius: 16px;
`;

export const DateText = styled(Typography)`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: ${mainTheme.palette.info.main};
`;

export const IconBox = styled(Box)`
  width: 40px;
  height: 40px;
  padding: 10px;
  background: #f4f4f4;
  border-radius: 14px;
`;
