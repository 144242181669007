import { useCallback, useEffect, useState } from "react";
import AwaitType from "../../../utils/AwaitType";
import { getUserById } from "../../verificationRequest/network";
import { TUserFullInfo } from "../../verificationRequest/models";

export type TResult = null | AwaitType<ReturnType<typeof getUserById>>;
export const useGetUserInfo = (
  data: any
): [TUserFullInfo, boolean, Error | null, () => void] => {
  const [userInfo, setUserInfo] = useState<TUserFullInfo>({
    phone: "",
    email: "",
    externalEmail: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      let response = { email: "", phone: "" };
      if (data?.userId) {
        response = await getUserById(data?.userId);
      }
      setUserInfo({ ...response, externalEmail: data.email });
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }
    setIsLoading(false);
  }, [data]);

  useEffect(() => {
    request();
  }, [request, data]);

  return [userInfo, isLoading, error, request];
};
