import { mainTheme } from "../../../../theme/mainTheme";
import { ConfirmationDialog } from "../../../ui/components/ConfirmationDialog";

export type Props = {
  onClose: () => void;
  open: boolean;
};

export const BinaryDataErrorDialog = (props: Props) => {
  const { onClose, open } = props;

  return (
    <ConfirmationDialog
      title='Data error'
      description={"Binary date missing in identification documents"}
      confirmText={"Cancel"}
      confirmAction={onClose}
      titleColor={mainTheme.palette.error.main}
      descriptionColor={mainTheme.palette.error.main}
      onClose={onClose}
      isOpen={open}
      confirmButtonColor={"primary"}
      withoutDecline
    />
  );
};
