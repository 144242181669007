import { styled, Typography } from "@mui/material";

export const LabelText = styled(Typography)`
  width: 100%;
`;

export const ValueText = styled(Typography)`
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  text-transform: capitalize;
  & svg {
    vertical-align: middle;
  }
`;
