import { Box, styled, Typography } from "@mui/material";
import { mainTheme } from "../../../../theme/mainTheme";

export const StateBlock = styled(Box)`
  padding: 5px 8px;
  background: #d7e7ff;
  border-radius: 6px;
`;

export const StateLabel = styled(Typography)`
  font-weight: 700;
  color: ${mainTheme.palette.primary.main};
`;

export const StatusBlock = styled(Box)`
  padding: 5px 10px;
  background: #d7e7ff;
  border-radius: 6px;
`;

export const StatusLabel = styled(Typography)`
  padding-left: 25px;
  font-weight: 700;
  color: ${mainTheme.palette.warning.main};
`;
