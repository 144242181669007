import { ComponentProps, memo } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { mainTheme } from "../../../../theme/mainTheme";

export const CreateButton = memo((props: ComponentProps<typeof Button>) => {
  const { children, ...rest } = props;
  return (
    <Button
      variant='contained'
      startIcon={<AddIcon />}
      {...rest}
      sx={{ backgroundColor: mainTheme.palette.primary.main }}
    >
      {children || "Create"}
    </Button>
  );
});
