import { styled, Typography, Button } from "@mui/material";
import { mainTheme } from "../../../../theme/mainTheme";

export const CompanyCard = styled(Button)<{
  disabled?: boolean;
  theme?: any;
  selected?: boolean;
}>(({ disabled, theme, selected }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  position: "relative",
  padding: "12px 16px",
  width: "338px",
  height: "74px",
  background: disabled
    ? "#DEE0E4"
    : selected
    ? mainTheme.palette.primary.main
    : "#f4f4f4",
  color: disabled
    ? mainTheme.palette.info.main
    : selected
    ? "#FFFFFF"
    : "#1c1f21",
  borderRadius: "12px",
  "&:hover": {
    background: selected && mainTheme.palette.primary.main,
    color: selected && "#FFFFFF",
    opacity: selected && "0.8",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
    padding: "10px",
  },
}));

export const CompanyName = styled(Typography)`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;

export const CompanyID = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: capitalize;
  ${({ theme }) => theme.breakpoints.down("sm")} {
    font-size: 12px;
  }
`;
