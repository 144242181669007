import countries from "../../../data/countries.json";
import { ICountryRegex } from "../../company/models";

export const getParsedTaxId = (taxId: any, countriesRegex: any) => {
  const regexCountry = Object.keys(taxId)[0];
  const viewCountry =
    countries.find((countryItem) => countryItem.code === regexCountry)?.name ||
    regexCountry;

  const regexConfig = countriesRegex[regexCountry] as ICountryRegex;
  const regex = regexConfig?.regexp ? new RegExp(regexConfig.regexp) : "";
  const check =
    regex && taxId[regexCountry] ? regex.test(taxId[regexCountry]) : "none";
  const value = taxId[regexCountry];
  return { viewCountry, example: regexConfig?.example, check, value };
};
