import { styled, Button, Popover } from "@mui/material";
import { mainTheme } from "../../../../theme/mainTheme";

export const HeadButton = styled(Button)`
  position: relative;
  text-transform: capitalize;
  font-family: "Red Hat Display";
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.5px;
  color: ${mainTheme.palette.primary.main};
`;
export const FilterButton = styled(Button)`
  padding: 8px, 16px, 8px, 16px;
  width: 100%;
  height: 32px;
  box-shadow: none;
  border-radius: 12px;
  text-transform: none;
  font-weight: 700;
`;

export const ClearButton = styled(FilterButton)`
  background-color: #f4f4f4;
  color: #1c1f21;
  &:hover {
    background-color: #dddddd;
  }
`;

export const FilterPopover = styled(Popover)`
  & .MuiPaper-root {
    padding: 24px;
    border-radius: 16px;
    min-width: 320px;
  }
`;
