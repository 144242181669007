import { useCallback, useEffect, useState } from "react";
import AwaitType from "../../../utils/AwaitType";
import { getRegexCountries, getRegexList } from "../network";
import { countrySelectData } from "../../../data/countriesData";
import { SelectData } from "mui-rff";
import { useVerificationContext } from "../../verificationRequest/context/useVerification";

export type TResult = null | AwaitType<ReturnType<typeof getRegexList>>;
export const useRegexList = (
  getLikeObject?: boolean
): [any | null, SelectData[], boolean, Error | null, () => void] => {
  const { setCountriesRegex } = useVerificationContext();
  const [regexList, setRegexList] = useState<any | null>(null);
  const [availCountries, setAvailCountries] = useState<SelectData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      let response: any = {};
      if (getLikeObject) {
        response = await getRegexCountries();
      } else {
        response = await getRegexList();
        setAvailCountries(
          countrySelectData.filter(
            (countrySelect) =>
              !response.subItems?.includes(countrySelect.value as string)
          )
        );
      }

      setCountriesRegex(response);
      setRegexList(response);
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      } else {
        setError(new Error("Unknown error"));
      }
    }
    setIsLoading(false);
  }, [getLikeObject, setCountriesRegex]);

  useEffect(() => {
    request();
  }, [request]);

  return [regexList, availCountries, isLoading, error, request];
};
