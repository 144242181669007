import { isArray } from "lodash";

const autoCompare = (
  valueA: any,
  valueB: any,
  valueType: "string" | "number" = "string",
  direction: "desc" | "asc",
  condition?: boolean //при котором автоматом значение наверх
) => {
  if (direction === "desc") {
    if (
      !condition &&
      (valueType === "number"
        ? Number(valueB) > Number(valueA)
        : valueB > valueA)
    ) {
      return 1;
    }
    if (
      (valueType === "number"
        ? Number(valueB) < Number(valueA)
        : valueB < valueA) ||
      condition
    ) {
      return -1;
    }
    return 0;
  } else {
    if (
      !condition &&
      (valueType === "number"
        ? Number(valueB) < Number(valueA)
        : valueB < valueA)
    ) {
      return 1;
    }
    if (
      (valueType === "number"
        ? Number(valueB) > Number(valueA)
        : valueB > valueA) ||
      condition
    ) {
      return -1;
    }
    return 0;
  }
};

export const getSortDescending = (
  array: any[],
  field?: string | string[], //если поле вложенное, путь до него по порядку в массиве
  options: {
    condition?: boolean | ((arg: any) => boolean);
    type?: "string" | "number";
  } = { type: "string" }
) => {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      const finalCondition =
        options?.condition &&
        (typeof options.condition === "function"
          ? options.condition(a)
          : options.condition);

      if (field && isArray(field)) {
        let finalFieldA = { ...a };
        let finalFieldB = { ...b };
        for (let dataField of field) {
          finalFieldA = finalFieldA[dataField];
          finalFieldB = finalFieldB[dataField];
        }
        return autoCompare(
          finalFieldA,
          finalFieldB,
          options.type,
          "desc",
          finalCondition
        );
      } else if (field) {
        return autoCompare(
          a[field],
          b[field],
          options.type,
          "desc",
          finalCondition
        );
      } else {
        return autoCompare(a, b, options.type, "desc", finalCondition);
      }
    });
  } else {
    return [];
  }
};

export const getSortAscending = (
  array: any[],
  field?: string | string[], //если поле вложенное, путь до него по порядку в массиве
  options: {
    condition?: boolean | ((arg: any) => boolean);
    type?: "string" | "number";
  } = { type: "string" }
) => {
  if (Array.isArray(array)) {
    return array.sort((a, b) => {
      const finalCondition =
        options?.condition &&
        (typeof options.condition === "function"
          ? options.condition(a)
          : options.condition);

      if (field && isArray(field)) {
        let finalFieldA = { ...a };
        let finalFieldB = { ...b };
        for (let dataField of field) {
          finalFieldA = finalFieldA[dataField];
          finalFieldB = finalFieldB[dataField];
        }
        return autoCompare(
          finalFieldA,
          finalFieldB,
          options.type,
          "asc",
          finalCondition
        );
      } else if (field) {
        return autoCompare(
          a[field],
          b[field],
          options.type,
          "asc",
          finalCondition
        );
      } else {
        return autoCompare(a, b, options.type, "asc", finalCondition);
      }
    });
  } else {
    return [];
  }
};
