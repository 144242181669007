import { useCallback, useEffect, useState } from "react";
import { useVerificationRequestAccept } from "../../hooks/useVerificationRequestAccept";
import { useVerificationRequestDecline } from "../../hooks/useVerificationRequestDecline";
import { ConfirmationDialog } from "../../../ui/components/ConfirmationDialog";
import { DeclineReasonForm } from "../DeclineReasonForm";
import { useRejectReason } from "../../hooks/useRejectReason";
import { mainTheme } from "../../../../theme/mainTheme";

export type Props = {
  onClose: (reason: "cancel" | "accept" | "decline") => void;
  open: boolean;
  isNamesEqual: boolean;
  documentId: string;
  status: string;
  value: "accept" | "decline" | null;
  identificationDocumentType: "Passport" | "ID" | null;
};

export const DocumentReviewDialog = (props: Props) => {
  const {
    documentId,
    onClose,
    open,
    value,
    status,
    isNamesEqual,
    identificationDocumentType,
  } = props;
  const [acceptResult, acceptLoading, acceptError, acceptRequest] =
    useVerificationRequestAccept();
  const [declineResult, declineLoading, declineError, declineRequest] =
    useVerificationRequestDecline();
  const [reason, setReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [handleDecline, validationErrors, setValidationErrors] =
    useRejectReason(reason, otherReason, documentId, declineRequest);
  const error = acceptError || declineError;
  const loading = acceptLoading || declineLoading;

  useEffect(() => {
    if ((acceptResult || declineResult) && value) {
      onClose(value);
    }
  }, [acceptResult, declineResult, onClose, value]);

  const handleCancel = useCallback(() => {
    setValidationErrors({});
    onClose("cancel");
  }, [onClose, setValidationErrors]);

  const handleOk = useCallback(() => {
    if (value === "accept") {
      acceptRequest(documentId);
    } else if (value === "decline") {
      handleDecline();
    }
  }, [acceptRequest, value, documentId, handleDecline]);

  return (
    <ConfirmationDialog
      title='Are you sure?'
      description={
        value === "accept"
          ? status === "valid"
            ? isNamesEqual
              ? "After this action, the entry will be accepted"
              : identificationDocumentType
              ? `Names from ${identificationDocumentType} don't match with names from User Information! Are you sure you want to accept?`
              : "Binary date missing in identification documents"
            : 'The database screening is not in "Valid" status - some KYC steps might be missing. Are you sure you want to accept?'
          : "After this action, the entry will be rejected"
      }
      titleColor={
        status === "valid" && isNamesEqual
          ? "inherit"
          : mainTheme.palette.error.main
      }
      descriptionColor={
        status === "valid" && isNamesEqual
          ? "inherit"
          : mainTheme.palette.error.main
      }
      error={error}
      confirmText={value === "accept" ? "Accept" : "Reject"}
      confirmAction={handleOk}
      disabled={loading}
      onClose={handleCancel}
      isOpen={open}
      confirmButtonColor={value === "accept" ? "success" : "error"}
    >
      {value === "decline" ? (
        <DeclineReasonForm
          reason={reason}
          otherReason={otherReason}
          setReason={setReason}
          setOtherReason={setOtherReason}
          validationErrors={validationErrors}
        />
      ) : null}
    </ConfirmationDialog>
  );
};
